import { IEnvironment } from './i-environment';

export const environment: IEnvironment = {
  production: true,
  baseURL: '/api/v1/',
  logLevel: "information",
  logType: "backend",
  salt: 'QTZ4blFoYno0VngySHVHbDRsWHdaNVUySThpemlMUkZuaFA1ZU5mSVJ2UQ',
  serverURL: '',
  maxProfileFieldsCount: 50,
  envName: 'uat',
  translationsUrlPrefix: 'https://i18n.uat.ownid.com/',
  translationsUrlSuffix: '/web-app.json',
  allowMocks: false,
  logRocket: {
    'passwordless.carnival.com': '8aznfr/webapp-carnival',
    'passwordless-www4.nonprod.carnivalcloud.net': '8aznfr/webapp-carnival',
    'passwordless.carrefour.be': '8aznfr/webapp-carrefour',
    'passwordless.aem.carrefour.be': '8aznfr/webapp-carrefour',
  }
};
